import { useRef, useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import axios from '../../api/axios';
const LOGIN_URL = '/v1/auth'

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErr('');
  }, [email, password])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify({
        email,
        password,
        application: process.env.REACT_APP_IDENTITY_APP_ID
      }), { withCredentials: true });

      const accessToken = response?.data?.accessToken;
      
      const user = jwt_decode(response.data.accessToken);

      setAuth({accessToken, user});
      setEmail('');
      setPassword('');
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErr('Something went wrong. Please try again later.');
      } else if (err.response?.status === 400) {
        setErr('Invalid email or password.');
      } else if (err.response?.status === 401) {
        setErr('User is not verfied.');
      } else if (err.response?.status === 503) {
        setErr('Service is not available. Please try again later.');
      } else {
        setErr('Something went wrong. Please try again later.');
      }
      errRef.current.focus();
    }
  }

  useEffect(() => {
    localStorage.setItem("persist", persist)
  }, [persist]) 

  return (
    <section>
      <Row className='justify-content-center'>
        <Col md={8}>
          <Card>
            <Card.Header>Inloggen</Card.Header>
            <Card.Body>
            <Alert ref={errRef} hidden={!err} variant='danger'>
              {err}
            </Alert>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control 
                    placeholder="E-mailadres" 
                    type="email" 
                    ref={userRef}
                    autoComplete="username"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control 
                    type="password" 
                    placeholder="Wachtwoord"
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    label="Vertrouw dit apparaat"
                    onChange={e => setPersist(e.target.checked)}
                    checked={persist}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Inloggen
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  )
}

export default Login