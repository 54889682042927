import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

// Import pages / middleware
import RequireAuth from "./middleware/RequireAuth";
import PersistLogin from './middleware/PersistLogin';
import Login from "./pages/auth/Login";
import Dashboard from './pages/Dashboard';
import Unauthorized from "./pages/misc/Unauthorized";
import Unknown from "./pages/misc/Unknown";
import ApplicationList from './pages/applications/ApplicationList';
import GuestLayout from './pages/layouts/GuestLayout';
import AuthLayout from './pages/layouts/AuthLayout';

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      {/* public routes */}
      <Route element={<GuestLayout />}>
        <Route path="login" element={<Login/>} />
        <Route path="unauthorized" element={<Unauthorized/>} />    
      </Route>

        {/* Authenticated Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<AuthLayout/>}>
            <Route element={<RequireAuth />}>
                <Route path="/" element={<Dashboard />} /> 
                <Route path="/applications" element={<ApplicationList />} />
            </Route>
          </Route>
        </Route>
        
      {/* catch all */}
      <Route path="*" element={<Unknown />} />
    </Routes>
  );
}

export default App;
