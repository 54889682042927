import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import useAuth from '../../hooks/useAuth'
import useLogout from '../../hooks/useLogout'
import { NavLink } from 'react-router-dom'

function AuthNavbar() {
  const logout = useLogout();
  const { auth } = useAuth();

  
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={NavLink} to="/">Intranet</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">Dashboard</Nav.Link>
            <Nav.Link as={NavLink} to="/applications">Applications</Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown title={auth.user?.firstName + ' ' + auth.user?.lastName} id="collasible-nav-dropdown">
              <NavDropdown.Item href="#">Instellingen</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logout()}>Uitloggen</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AuthNavbar;