import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { NavLink } from 'react-router-dom';

function GuestNavbar() {
  return (
    <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand>
        <NavLink to="/" className="text-reset text-decoration-none">Intranet</NavLink>
      </Navbar.Brand>
    </Container>
    </Navbar>
  )
}

export default GuestNavbar;