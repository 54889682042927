import { Outlet } from 'react-router-dom'
import GuestNavbar from '../../components/navigation/GuestNavbar'
import Container from 'react-bootstrap/Container'

const GuestLayout = () => {
  return (
    <>
      <GuestNavbar />
      <main>
        <Container className='mt-3'>
          <Outlet />
        </Container>
      </main>
    </>
  )
}

export default GuestLayout;