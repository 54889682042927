import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

const ApplicationList = () => {
  const [ applications, setApplications ] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController()

    const fetchApplications = async () => {
        try {
          const response = await axiosPrivate.get('/v1/applications', { signal: controller.signal });
          console.log(response.data);
          isMounted && setApplications(response.data);
        } catch (error) {
          console.log(error)
          error?.response?.status === 401 && navigate('/login', { state: { from: location }, replace: true })
        }
    }

    fetchApplications();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);

  return (
    <Row className="justify-content-center">
      <Col md={12} lg={8}>
        <h3>Applications</h3>
        <Table bordered hover>
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Active</th>
              <th scope="col">Onboarding</th>
            </tr>
          </thead>
          <tbody>
            {applications?.map(application => (
              <tr key={application._id}>
                <td><NavLink to={"/applications/" + application._id}>{application._id}</NavLink></td>
                <td>{application.name}</td>
                <td>{application.active ? (<Badge bg="primary">Active</Badge>) : (<Badge bg="danger">Not active</Badge> )}</td>
                <td>{application.onboarding ? (<Badge bg="success">Enabled</Badge>) : (<Badge bg="secondary">Disabled</Badge> )}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ApplicationList