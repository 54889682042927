import axios from "../api/axios";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    setAuth({});
    try {
      await axios('/logout', {
        withCredentials: true
      });
    } catch (error) {
      console.error(error)
    }
    
    navigate('/login');
  }

  return logout;
}

export default useLogout;