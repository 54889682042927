import axios from '../api/axios'
import useAuth from './useAuth'
import jwt_decode from 'jwt-decode';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  
  const refresh = async () => {
    const response = await axios.get('/v1/auth/refresh', {
      withCredentials: true
    });

    setAuth(prev => {
      const user = jwt_decode(response.data.accessToken);

      return {
        ...prev,
        user,
        accessToken: response.data.accessToken
      }
    })

    return response.data.accessToken;
  }

  return refresh;
}

export default useRefreshToken