import axios from 'axios'

export default axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_URL,
  headers: { 'Content-Type': 'application/json' }
});