import { Outlet } from 'react-router-dom'
import AuthNavbar from '../../components/navigation/AuthNavbar';
import Container from 'react-bootstrap/Container'

const AuthLayout = () => {
  return (
    <>
      <AuthNavbar />
      <main>
        <Container className='mt-3'>
          <Outlet />
        </Container>
      </main>
    </>
  )
}

export default AuthLayout;