import { useNavigate } from 'react-router-dom';

const Unknown = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section>
      <h1>404</h1>
      <br />
      <p>Page not found.</p>
      <button onClick={goBack}>Go Back</button>
    </section>
  );
};

export default Unknown;