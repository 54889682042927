import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedPermission }) => {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    allowedPermission ?
      auth?.user?.role?.permissions?.find(permission => permission.slug === allowedPermission && permission.active)
        ? <Outlet /> 
        : auth?.accessToken
          ? <Navigate to={`/unauthorized`} state={{from: location}} replace />
          : <Navigate to={`/login`} state={{from: location}} replace />
      : auth?.accessToken
        ? <Outlet />
        : <Navigate to={`/login`} state={{from: location}} replace />
  );
}

export default RequireAuth;